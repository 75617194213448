import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import './App.css';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  button: {
    margin: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(3, 2),
    backgroundColor: 'lightblue',
  }
}));


const useGeolocation = () => {
  const [location, setLocation] = useState({});
  const [error, setError] = useState();
  const onChange = ({ coords }) => {
    setLocation({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      timestamp: new Date().toLocaleTimeString()
    });
  };

  const onError = (error) => {
    setError(error.message);
  };

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Geolocation is not supported by this browser');
      return;
    };

    let watcher = geo.watchPosition(onChange, onError, { enableHighAccuracy: true });
    return () => geo.clearWatch(watcher);
  }, []);

  return { ...location, error };
}

const ShowLocationAsDegrees = (props) => {
  const classes = useStyles();
  const decimalAccuracy = 10 ** 5; // 5 decimal places is approx 1m resolution
  const latitude = Math.round(props.coords.latitude * decimalAccuracy) / decimalAccuracy;
  const longitude = Math.round(props.coords.longitude * decimalAccuracy) / decimalAccuracy;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5">Location displayed as Degrees only</Typography>
        <Typography>Latitude: {latitude} degrees</Typography>
        <Typography>Longitude: {longitude} degrees</Typography>
      </CardContent>
    </Card>
  )
}

const ShowLocationAsDegreesAndMinutes = (props) => {
  const classes = useStyles();
  const decimalAccuracy = 10 ** 3; // 3 decimal places
  const latitudeDegrees = props.coords.latitude < 0 ? Math.ceil(props.coords.latitude) : Math.floor(props.coords.latitude);
  const latitudeMinutes = Math.abs(Math.round(((props.coords.latitude - latitudeDegrees) * 60) * decimalAccuracy) / decimalAccuracy);
  const longitudeDegrees = props.coords.longitude < 0 ? Math.ceil(props.coords.longitude) : Math.floor(props.coords.longitude);
  const longitudeMinutes = Math.abs(Math.round(((props.coords.longitude - longitudeDegrees) * 60) * decimalAccuracy) / decimalAccuracy);
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5">Location displayed as Degrees and Minutes</Typography>
        <Typography>Latitude: {latitudeDegrees} degrees {latitudeMinutes} minutes</Typography>
        <Typography>Longitude: {longitudeDegrees} degrees {longitudeMinutes} minutes</Typography>
      </CardContent>
    </Card>
  )
}

const ShowLocationAccuracy = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
      <Typography variant="h5">Location Accuracy (smaller is more accurate)</Typography>
        <Typography>Accuracy: {props.coords.accuracy && Math.round(props.coords.accuracy)}m</Typography>
        <Typography>Time: {props.coords.timestamp}</Typography>
      </CardContent>
    </Card>
  )
}

const ShowLocation = () => {
  const coords = useGeolocation();
  if (coords.error) {
    return (<div>{coords.error}</div>)
  } else {
    return (
      <div>
        <ShowLocationAccuracy coords={coords} />
        <ShowLocationAsDegrees coords={coords} />
        <ShowLocationAsDegreesAndMinutes coords={coords} />
      </div>
    )
  }
}

function App() {
  const [showLocation, setShowLocation] = useState(false);
  const classes = useStyles();
  return (
    <div className="App">
      <Paper className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h5">Find Me Here Now</Typography>
          </Toolbar>
        </AppBar>
        {!showLocation &&
          <Button variant="contained" color="primary" className={classes.button} onClick={() => setShowLocation(true)}>Show Location</Button>}
        {showLocation && <ShowLocation />}
      </Paper>
    </div>
  );
}

export default App;
